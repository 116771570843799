import React from 'react';
import ResumeDoodleLantern from './ResumeDoodleLantern';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <div class="lantern">
          <ResumeDoodleLantern />
        </div>
        <p> Work In Progress </p>
      </div>
    </div>
  );
}

export default App;
