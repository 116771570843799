import React from 'react';

export default class ResumeDoodleLantern extends React.Component {

	render() {
		return (
			<svg
		   viewBox="0 0 156.06081 193.04697" className='lantern'
		   id="resumeDoodleLantern">
			  <g
			    transform="translate(4.4991255e-7,-21.173111)">
			    <path
			       transform="scale(0.26458333)"
			       style={{opacity:'0.85',fill:'#1fd1ff',fillOpacity:'1',stroke:'#1fd1ff',strokeWidth:'9.82677174',strokeLinejoin:'round',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1'}}
			       d="m 188.80776,261.38315 c -4.125,-2.68591 -7.5,-5.34915 -7.5,-5.9183 0,-1.10949 27.45621,-18.43002 28.13653,-17.7497 0.50296,0.50296 -11.9442,28.67944 -12.63653,28.60514 -0.275,-0.0295 -3.875,-2.25123 -8,-4.93714 z m -58.34413,82.5084 C 99.199356,331.60756 73.470447,321.40797 73.288276,321.2258 73.106105,321.04363 95.126164,307.29644 122.22174,290.67648 l 49.26469,-30.21811 11.41066,7.47619 c 11.40362,7.47156 11.41051,7.4785 11.15792,11.22679 -0.74269,11.02062 -6.30268,83.99821 -6.50702,85.40767 -0.19522,1.34658 -10.89118,-2.52778 -57.08436,-20.67747 z m 5.66554,303.75413 -69.178585,-43.60085 -6.465879,-17.56644 c -3.556233,-9.66154 -6.354464,-17.68784 -6.21829,-17.83622 0.136173,-0.14838 30.428329,11.49877 67.315904,25.88256 l 67.06831,26.15236 3.6582,8.00284 c 4.72874,10.34483 10.46623,27.62996 15.01394,45.23203 1.9894,7.7 3.84269,14.7875 4.11842,15.75 0.40849,1.42587 -0.11308,1.73468 -2.81604,1.66729 -2.57361,-0.0642 -18.82768,-9.8583 -72.49598,-43.68357 z M 117.42252,583.56003 49.537289,556.91196 34.087949,515.07987 C 25.590812,492.07222 18.789168,473.10505 18.973185,472.9306 c 0.184018,-0.17445 38.022077,9.42044 84.084575,21.32196 l 83.75,21.63914 v 47.26013 c 0,25.99307 -0.3375,47.21426 -0.75,47.1582 -0.4125,-0.0561 -31.29836,-12.09356 -68.63524,-26.75 z m -17.3013,-99.01709 C 54.368629,472.6459 16.639528,462.60786 16.278778,462.23618 15.268074,461.19486 67.00688,329.41196 68.426415,329.41196 c 0.679137,0 27.480269,10.31969 59.558065,22.93264 l 58.32328,22.93264 0.25719,65.56736 c 0.24326,62.01662 0.16203,65.56091 -1.5,65.44834 -0.96645,-0.0655 -39.19113,-9.85296 -84.94373,-21.75 z" />

			    <path
			       style={{opacity:'1',fill:'#ED7C75',fillOpacity:'1',stroke:'#ED7C75',strokeWidth:'19.65354347',strokeLinejoin:'round',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1'}}
			       d="m 183.09698,783.31196 c 3.77848,-12.90791 22.20379,-62.45876 26.03767,-70.02264 l 2.79924,-5.52263 80.36659,0.52263 80.36659,0.52264 8.31615,21.18704 c 10.06614,25.64545 20.82454,57.16309 20.82454,61.00719 0,2.37396 -17.06646,2.80577 -110.8922,2.80577 h -110.8922 z"
			       transform="scale(0.26458333)" />
			    <path
			       style={{opacity:'1',fill:'#ED7C75',fillOpacity:'1',stroke:'#ED7C75',strokeWidth:'19.65354347',strokeLinejoin:'round',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1'}}
			       d="m 223.30776,218.4333 c -3.09649,-0.80919 -3.5,-3.7799 -3.5,-25.76799 v -24.85335 h 78 78 v 26 26 l -74.5,-0.23201 c -40.975,-0.1276 -76.075,-0.64359 -78,-1.14665 z"
			       transform="scale(0.26458333)" />
			    <path
			       d="m 108.09064,69.157623 c 1.0914,-0.710647 1.98437,-1.415296 1.98437,-1.565883 0,-0.293553 -7.26445,-4.876276 -7.44445,-4.696275 -0.13308,0.133075 3.16023,7.588102 3.34341,7.568443 0.0728,-0.0078 1.02526,-0.595638 2.11667,-1.306285 z m 15.43688,21.830347 c 8.27201,-3.250139 15.07945,-5.94878 15.12765,-5.996979 0.0482,-0.0482 -5.77794,-3.685477 -12.94698,-8.082841 l -13.03462,-7.995208 -3.01907,1.978075 c -3.01721,1.97685 -3.01903,1.978687 -2.9522,2.970422 0.19651,2.915872 1.66759,22.224526 1.72165,22.597445 0.0517,0.356283 2.88163,-0.668808 15.10357,-5.470914 z m -1.49901,80.36827 18.3035,-11.53606 1.71077,-4.64778 c 0.94092,-2.55629 1.68128,-4.67991 1.64525,-4.71917 -0.036,-0.0393 -8.05082,3.04238 -17.81066,6.84809 l -17.74516,6.91948 -0.9679,2.11742 c -1.25114,2.73707 -2.76919,7.31043 -3.97244,11.96764 -0.52636,2.03729 -1.01671,3.91253 -1.08966,4.16719 -0.10808,0.37726 0.0299,0.45897 0.74508,0.44114 0.68093,-0.017 4.98149,-2.60835 19.18122,-11.55795 z m 4.94947,-16.95599 17.9613,-7.05064 4.08764,-11.06807 c 2.2482,-6.08744 4.0478,-11.10584 3.99911,-11.152 -0.0487,-0.0461 -10.06,2.49249 -22.24737,5.64144 l -22.15886,5.72535 v 12.50425 c 0,6.87733 0.0893,12.4921 0.19844,12.47727 0.10914,-0.0148 8.28103,-3.19975 18.15974,-7.0776 z m 4.57764,-26.19828 c 12.10537,-3.14775 22.08786,-5.80365 22.18331,-5.90199 0.26742,-0.27552 -13.42181,-35.143068 -13.7974,-35.143068 -0.17968,0 -7.27082,2.730418 -15.75807,6.067595 l -15.43136,6.067589 -0.068,17.348024 c -0.0644,16.40857 -0.0429,17.34633 0.39687,17.31654 0.25571,-0.0173 10.36932,-2.60693 22.4747,-5.75469 z"
			       style={{opacity:'0.85',fill:'#1fd1ff',fillOpacity:'1',stroke:'#1fd1ff',strokeWidth:'2.5999999',strokeLinejoin:'round',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1'}}
			       />
			  </g>
			  <g

			     transform="translate(-30.070785,-105.92016)">
			    <path
			      style={{opacity:'0.97699998',fill:'none',fillOpacity:'1',stroke:'#32383d',strokeWidth:'5.0999999',strokeLinejoin:'round',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1'}}

			       d="M 180.67262,266.00595 124.3231,297.31289 60.333618,289.51932 13.145988,245.60212 0.78427607,182.33617 27.970237,123.8869 84.319756,92.579961 l 63.989474,7.793569 47.18763,43.9172 12.36172,63.26596 z"
			       transform="matrix(0.72563791,-0.0727966,0.08228982,0.6201564,16.359501,94.296758)" />
			    <path
			       style={{opacity:'1',fill:'none',stroke:'#32383d',strokeWidth:'3.74900007',strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1'}}
			       d="m 84.49353,270.11758 c 0,0 -11.486526,26.85186 -8.441986,26.88236 20.793948,0.20834 62.384976,0 62.384976,0 2.97011,0 -8.44198,-26.88236 -8.44198,-26.88236 z"

			      />

			    <path
			       style={{ opacity:'1',fill:'none',stroke:'#32383d',strokeWidth:'3.74900007',strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1' }}
			       d="m 86.207848,144.72167 v -17.89653 h 45.501002 v 18.42569 z"
			      />
			   <path
			       style={{opacity:'1',fill:'none',fillOpacity:'1',stroke:'#333c41',strokeWidth:'3.74900007',strokeLinejoin:'round',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1'}}
			       d="m 117.3633,117.1491 c 0,5.16632 -4.0206,8.29611 -8.98026,8.29611 -4.95966,-1e-5 -8.980254,-3.1298 -8.980254,-8.29611 0,-5.16631 4.020594,-9.35443 8.980254,-9.35444 4.95966,0 8.98026,4.18812 8.98026,9.35444 z"

			       />
			    <path

			       style={{opacity:'0.97699998',fill:'#ffcc00',fillOpacity:'1',stroke:'#d4aa00',strokeWidth:'1',strokeLinejoin:'round',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1'}}
			       d="m 109.27317,223.84269 c 0,12.49372 -10e-6,21.82813 -10e-6,21.82813 12.12841,4e-5 22.24494,-12.1459 20.32611,-20.31875 -0.64039,-2.7276 -0.0306,-2.14029 -5.97463,-11.73883 -3.22661,-5.21041 -5.71924,-9.35003 -10.047,-17.2614 -1.91031,-3.49215 -5.07041,-10.86743 -4.73716,-8.2458 0.33068,2.60148 -0.12429,0.57832 0.17727,6.42764 0,0 0.14029,7.56218 0.15149,8.68271 0.0115,1.12053 0.0269,2.7447 0.0423,4.75931 0.0154,2.0146 0.0308,4.41964 0.0423,7.10188 0.0115,2.68224 0.0192,5.64168 0.0192,8.76511 z m -0.52943,0 c 0,12.49372 1e-5,21.82813 1e-5,21.82813 -12.12841,4e-5 -22.244937,-12.1459 -20.32611,-20.31875 0.640389,-2.7276 0.03062,-2.14029 5.974631,-11.73883 3.226607,-5.21041 5.719239,-9.35003 10.046999,-17.2614 1.91031,-3.49215 5.07041,-10.86743 4.73716,-8.2458 -0.33068,2.60148 0.12429,0.57832 -0.17727,6.42764 0,0 -0.14029,7.56218 -0.15149,8.68271 -0.0115,1.12053 -0.0269,2.7447 -0.0423,4.75931 -0.0154,2.0146 -0.0308,4.41964 -0.0423,7.10188 -0.0115,2.68224 -0.0192,5.64168 -0.0192,8.76511 z" />
			    <path

			       style={{ opacity :'1',fill :'none',stroke :'#32383d',strokeWidth :'2.065',strokeLinecap :'butt',strokeLinejoin :'miter',strokeMiterlimit :'4',strokeDasharray :'none',strokeOpacity :'1' }}
			       d="m 136.73111,220.35509 47.53825,-12.4719 m -152.332348,-0.11239 48.76963,12.58829 m 49.440768,-73.45837 4.41498,9.86414 2.01121,26.30867 v 37.04154 l -0.0468,27.5725 c -3.34239,6.28995 -5.26791,13.76832 -6.96338,20.61294 M 87.198975,146.0864 l -4.481126,10.92247 -2.011207,26.30867 v 37.04154 l 0.04678,27.5725 c 3.342384,6.28995 5.135607,13.50374 6.831083,20.34836 m -11.608512,-116.02421 6.741856,4.75314 51.844541,-0.24401 6.36881,-4.17845 m -98.937869,80.32743 38.73171,15.10282 56.018459,-0.0238 35.22772,-12.00148 m -125.120824,-65.96606 33.856246,13.29222 56.024468,-0.004 33.36198,-12.82981"/>
			    <path
			       style={{ opacity:'1',fill:'#32383d',fillOpacity:'1',stroke:'#32383d',strokeWidth:'23.51825905',strokeLinejoin:'round',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'1' }}
			       d="m 283.50765,613.04035 c -1.8001,-2.82577 -1.5099,-6.2457 2.59225,-30.54987 4.62188,-27.38333 5.52552,-29.83753 11.00421,-29.88611 4.73213,-0.042 6.34147,5.92472 8.86201,32.85616 1.98803,21.24154 2.13095,26.93621 0.71873,28.63783 -1.25413,1.51113 -4.55972,2.12397 -11.45647,2.12397 -8.50935,0 -9.94139,-0.38877 -11.72073,-3.18198 z"

			       transform="matrix(0.26458333,0,0,0.26458333,30.070785,105.92016)" />
						 <path

	 	       transform="matrix(0.26458333,0,0,0.26458333,30.070785,105.92016)"
	 	       style={{fill:'white'}}
	 	       d="m 319.59436,606.23299 c 0,-3.2208 -0.68368,-13.01577 -1.51929,-21.76661 -2.67661,-28.03044 -4.43752,-34.53147 -10.88732,-40.19447 l -3.66298,-3.21614 h 45.64178 c 36.13816,0 45.52802,0.26028 45.09541,1.25 -7.41078,16.95441 -13.76187,36.3402 -20.39407,62.25 l -1.79181,7 -26.24086,0.2666 -26.24086,0.26661 z m -99.39555,0.57278 c -0.79829,-2.8875 -3.32365,-12.225 -5.61192,-20.75 -3.79641,-14.1436 -11.17122,-35.41908 -14.64529,-42.25 l -1.3986,-2.75 46.27568,0.004 c 45.74871,0.004 46.24051,0.0252 43.18698,1.88721 -4.37367,2.66704 -7.17731,6.5857 -8.94076,12.49658 -2.31633,7.76408 -8.47054,44.91299 -8.47054,51.13109 v 5.48138 H 246.1223 221.65023 Z m -24.60445,-191.75 v -118 h 101.5 101.5 l -10e-4,78.25 c -7.5e-4,43.0375 -0.2989,96.1375 -0.66255,118 l -0.66155,39.75 H 296.43181 195.59436 Z m 121.59004,112.9841 c 25.83856,-6.58511 48.40042,-26.05465 58.14245,-50.1735 3.98774,-9.87265 4.84911,-17.85624 2.94842,-27.32736 -1.98699,-9.90116 -5.17737,-16.11934 -24.36236,-47.48324 -18.06588,-29.53436 -35.38023,-60.12424 -45.66055,-80.67013 -4.17478,-8.34357 -8.01509,-15.43253 -8.53401,-15.75324 -2.18786,-1.35217 -4.05678,1.22942 -11.00002,15.19463 -10.33896,20.79511 -24.18614,45.25715 -47.17554,83.33899 -10.99686,18.21625 -20.60449,35.27691 -21.49284,38.16572 -2.49795,8.12307 -2.983,20.13201 -1.10836,27.44121 6.51998,25.42125 32.16262,50.01337 59.65349,57.20962 10.52315,2.75463 27.90429,2.78044 38.58932,0.0573 z M 205.95277,187.59004 c 0.22375,-0.56885 3.21077,-7.87214 6.63783,-16.22954 l 6.23101,-15.19526 77.63638,0.5707 c 42.7,0.31389 77.89575,0.85177 78.21278,1.19527 1.06231,1.15106 13.21945,29.16238 12.81894,29.5362 -0.21744,0.20295 -41.24374,0.54628 -91.16955,0.76295 -62.27043,0.27025 -90.64647,0.0692 -90.36739,-0.64032 z m -9.38799,98.21573 c -0.0163,-1.2375 1.42036,-21.7125 3.1925,-45.5 l 3.22208,-43.25 h 93.78845 93.78845 l 0.46567,2.25 c 0.4541,2.19411 6.60989,83.52372 6.58497,87 -0.0119,1.65498 -5.4697,1.75 -100.51254,1.75 h -100.5 z" />
			  </g>

			</svg>

		);
	}
}
